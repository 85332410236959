import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentAgendaItem || _vm.isItemLoading)?_c(VContainer,[(_vm.isItemLoading)?_c(VSkeletonLoader,{attrs:{"loading":_vm.isItemLoading,"type":"card-header, article, actions"}}):_c(VCard,{attrs:{"height":"100%"}},[_c(VCardTitle,{staticClass:"text-h4"},[_c('span',[_vm._v(_vm._s(_vm.currentAgendaItem.title))]),_c(VSpacer),_c('agenda-menu')],1),_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.markdownDescription)}}),(_vm.votingAllowed)?_c(VCardActions,[_c('vote-buttons',{attrs:{"disabled":_vm.votingDisabled,"options":_vm.voteOptions,"results":_vm.currentAgendaItem.results},on:{"change":_vm.voteChanged},model:{value:(_vm.vote),callback:function ($$v) {_vm.vote=$$v},expression:"vote"}}),_c(VSpacer),(!_vm.votingDisabled && _vm.hasItemUpdatePermission)?_c(VBtn,{attrs:{"right":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.votingComplete(true)}}},[_vm._v("Done")]):_vm._e(),(_vm.votingDisabled && _vm.hasItemUpdatePermission)?_c(VBtn,{attrs:{"right":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.votingComplete(false)}}},[_vm._v("Re-Open")]):_vm._e()],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }