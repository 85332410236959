
import { Component, Vue } from "vue-property-decorator";
import TopBar from "./components/TopBar.vue";

@Component({
  components: {
    TopBar,
  },
})
export default class App extends Vue {}
