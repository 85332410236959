import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authGuard } from "../auth/authGuard";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:agendaDate(\\d{4}-\\d{2}-\\d{2})?",
    name: "Home",
    component: Home,
  },
  {
    path: "/:agendaDate(\\d+)",
    name: "Home",
    component: Home,
  },
  {
    path: "/agendas",
    name: "Agendas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "agendas" */ "../views/Agendas.vue"),
  },
  {
    path: "/users",
    name: "Users",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/me",
    name: "Profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/User.vue"),
    beforeEnter: authGuard,
    props: {
      title: "Profile",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
