
import { Component, Model, Prop, Vue } from "vue-property-decorator";
import type { VotingOption, VotingResults } from "~/types/Agenda";

const defaultOptions: () => unknown[] = () => [];

@Component({})
export default class VoteButtons extends Vue {
  @Prop()
  disabled!: boolean;

  @Prop({ default: defaultOptions })
  options!: VotingOption[];

  @Prop({ default: () => ({}) })
  results!: VotingResults;

  getResultCount(resultValue: keyof VotingResults): string {
    if (this.results && resultValue in this.results) {
      return `(${this.results[resultValue]})`;
    }
    return "";
  }

  @Model()
  vote!: unknown;
}
