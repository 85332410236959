import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_vm._t("default",null,{"on":on})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Edit Agenda Item")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Title*","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VSwitch,{attrs:{"label":"Allow voting"},model:{value:(_vm.voting),callback:function ($$v) {_vm.voting=$$v},expression:"voting"}})],1),_c(VCol,[(_vm.voting)?_c('tags-input',{model:{value:(_vm.voteOptions),callback:function ($$v) {_vm.voteOptions=$$v},expression:"voteOptions"}}):_vm._e()],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c(VCardActions,[_c(VSpacer),_c('text-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")]),_c('text-button',{attrs:{"disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }