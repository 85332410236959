import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c('h1',[_vm._v(_vm._s(_vm.agendaTitle))])])],1),(!_vm.agendaError)?_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VSheet,{attrs:{"rounded":"lg"}},[_c('agenda-item-list')],1)],1),_c(VCol,[_c(VSheet,{attrs:{"min-height":"80vh","rounded":"lg"}},[_c('agenda-details')],1)],1)],1):_c(VRow,[_c(VCol,[_c(VSheet,{attrs:{"min-height":"80vh","rounded":"lg"}},[_vm._v(" "+_vm._s(_vm.agendaError)+" ")])],1)],1),_c(VOverlay,{attrs:{"value":_vm.isAppLoading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }