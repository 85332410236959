import { AgendaDTO, Attendee } from "~/types/Agenda";
import { AgendaItem } from "./AgendaItem";

export class Agenda implements AgendaDTO {
  id: number;
  date: string | Date;
  attendees: (string | Attendee)[];
  items: AgendaItem[];

  constructor(agenda?: AgendaDTO) {
    if (agenda) {
      this.id = agenda.id;
      this.date = new Date(agenda.date);
      this.attendees = Array.from(agenda.attendees || []);
      this.items = agenda?.items.map((item) => new AgendaItem(item)) ?? [];
    } else {
      this.id = 0;
      this.date = new Date();
      this.attendees = [];
      this.items = [];
    }
  }
}
