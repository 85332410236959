import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCombobox,{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","label":"Enter a voting option","multiple":"","small-chips":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c('span',{staticClass:"subheading"},[_vm._v("Hit Enter to create ")]),_c(VChip,{attrs:{"color":`${_vm.colour}`,"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function({ attrs, item, parent, selected }){return [(item === Object(item))?_c(VChip,_vm._b({attrs:{"color":`${item.color}`,"input-value":selected,"label":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VIcon,{on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function({ index, item }){return [(_vm.editing === item)?_c(VTextField,{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c(VChip,{attrs:{"color":`${item.color}`,"label":"","small":""}},[_vm._v(" "+_vm._s(item.title)+" ")])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }