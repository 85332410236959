import { User } from "@auth0/auth0-spa-js";
import { GetterTree } from "vuex";
import { Agenda } from "~/data/Agenda";
import { AgendaItem } from "~/data/AgendaItem";
import { RootState } from ".";

const agendaGetters: GetterTree<RootState, RootState> = {
  currentAgenda(state): Agenda | null {
    return state.agenda;
  },
};

const agendaItemGetters: GetterTree<RootState, RootState> = {
  agendaItems(state): AgendaItem[] | null {
    return state.agenda?.items ?? null;
  },
  currentAgendaItem(state): AgendaItem | null {
    return state.currentAgendaItem;
  },
};

const permissionGetters: GetterTree<RootState, RootState> = {
  permissions(state): string[] {
    return state.accessToken?.permissions ?? [];
  },
  hasPermission(state): (permission: string) => boolean {
    return (permission: string) =>
      !!state.accessToken?.permissions?.includes(permission);
  },
  hasItemVotePermission(state): boolean {
    return !!state.accessToken?.permissions?.includes("vote:items");
  },
  hasItemUpdatePermission(state): boolean {
    return !!state.accessToken?.permissions?.includes("update:items");
  },
  hasItemCreatePermission(state): boolean {
    return !!state.accessToken?.permissions?.includes("create:items");
  },
  hasItemDeletePermission(state): boolean {
    return !!state.accessToken?.permissions?.includes("delete:items");
  },
  hasAgendaUpdatePermission(state): boolean {
    return !!state.accessToken?.permissions?.includes("update:agenda");
  },
  hasAgendaCreatePermission(state): boolean {
    return !!state.accessToken?.permissions?.includes("create:agenda");
  },
  hasAgendaDeletePermission(state): boolean {
    return !!state.accessToken?.permissions?.includes("delete:agenda");
  },
};

export const getters: GetterTree<RootState, RootState> = {
  ...agendaGetters,
  ...agendaItemGetters,
  ...permissionGetters,
  isAppLoading(state): boolean {
    return state.appLoading;
  },
  isItemLoading(state): boolean {
    return state.itemLoading;
  },
  currentUser(state): User | null {
    return state.user;
  },
};
