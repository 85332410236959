
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import type { AgendaDTO } from "~/types/Agenda";

import { Agenda } from "../data/Agenda";

import TextButton from "./TextButton.vue";

@Component({
  components: {
    TextButton,
  },
})
export default class AgendaEditDialog extends Vue {
  @Prop()
  agenda?: Agenda;

  @Prop({ default: false })
  value!: boolean;

  get dialog(): boolean {
    return this.value;
  }
  set dialog(newValue: boolean) {
    this.$emit("input", newValue);
  }

  @Watch("agenda")
  agendaChanged(update?: Agenda): void {
    this.date = new Date(update?.date || Date.now())
      .toISOString()
      .substring(0, 10);
  }

  loading = false;

  dateVal: string | null = null;

  date: string = new Date().toISOString().substring(0, 10);
  minDate: string = (() => {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return d.toISOString().substring(0, 10);
  })();

  @Getter
  hasAgendaUpdatePermission!: boolean;

  @Action("ADD_AGENDA")
  saveNew!: (item: AgendaDTO) => Promise<void>;
  @Action("UPDATE_AGENDA")
  saveUpdate!: (item: AgendaDTO) => Promise<void>;

  async save(): Promise<void> {
    this.loading = true;
    const agenda = new Agenda({
      id: this.agenda?.id || 0,
      date: this.date,
      attendees: [],
      items: [],
    });
    try {
      if (agenda.id === 0) {
        await this.saveNew(agenda);
      } else {
        await this.saveUpdate(agenda);
      }
      this.close();
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  close(): void {
    this.loading = false;
    this.dialog = false;
    this.$emit("close");
  }
}
