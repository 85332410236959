
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import type { AgendaItemDTO } from "~/types/Agenda";
import type { AgendaItem } from "~/data/AgendaItem";
import AgendaItemEdit from "./AgendaItemEdit.vue";

@Component({
  components: {
    AgendaItemEdit,
  },
})
export default class AgendaList extends Vue {
  sortedItems?: AgendaItem[] | null = null;

  addItemOpen = false;

  selectedItem = 0;

  @Action("SET_CURRENT_ITEM")
  selectItem!: (agendaItem: AgendaItemDTO) => void;

  addItem(): void {
    return;
  }

  @Getter
  hasItemCreatePermission!: boolean;
  @Getter
  hasItemUpdatePermission!: boolean;
  @Getter
  currentAgendaItem!: AgendaItem | null;
  @Getter
  agendaItems!: AgendaItem[] | null;

  getVoteColour(result: string): string | void {
    console.log("GETVOTECOLOUR", result);
    switch (result) {
      case "yes":
        return "success";
      case "no":
        return "error";
    }
  }

  @Watch("agendaItems")
  sortItems(newItems: AgendaItem[]): void {
    console.log("SORTING", newItems);
    this.sortedItems = [...newItems].sort((a, b) => {
      let o = 0;
      const aOrder = Number(a.order ?? 0);
      const bOrder = Number(b.order ?? 0);
      if (aOrder > bOrder) o = 1;
      if (aOrder < bOrder) o = -1;
      return o;
    });
  }
}
