import { AppData, UserDTO } from "~/types/User";

export class User implements UserDTO {
  email: string;
  id: string;
  name: string;
  roles: string[];
  preferences?: AppData["preferences"];

  constructor(user?: UserDTO) {
    if (user) {
      this.email = user.email;
      this.id = user.id;
      this.name = user.name;
      this.roles = user.roles;
      this.preferences = user.preferences;
    } else {
      this.email = "";
      this.id = "";
      this.name = "Unknown";
      this.roles = [];
    }
  }
}
