import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
// import { Auth0Plugin } from "./plugins/auth0";
import { Auth0Plugin } from "./auth";
// Import the Auth0 configuration
// import { domain, clientId, audience } from "../auth_config.json";

const domain: string = process.env.VUE_APP_AUTH0_DOMAIN as string;
const clientId: string = process.env.VUE_APP_AUTH0_CLIENTID as string;
const audience: string = process.env.VUE_APP_AUTH0_AUDIENCE as string;

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState: { targetUrl: string }) => {
    router.push(
      appState?.targetUrl ? appState.targetUrl : window.location.pathname
    );
  },
  store,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
