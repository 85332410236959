
import { Component, Model, Vue } from "vue-property-decorator";
import { VotingOption } from "~/types/Agenda";

@Component({})
export default class TagsInput extends Vue {
  colours = [
    "success",
    "error",
    undefined,
    "purple",
    "indigo",
    "cyan",
    "teal",
    "orange",
  ];
  nonce = 0;

  get colour(): string | undefined {
    return this.colours[this.nonce % this.colours.length];
  }

  @Model("update:model")
  readonly items!: VotingOption[];

  get model(): VotingOption[] {
    return Array.from(this.items);
  }
  set model(val: unknown[]) {
    const options: VotingOption[] = val.map<VotingOption>((v) => {
      if (typeof v === "string") {
        const item: VotingOption = {
          title: v,
          value: v.toLowerCase(),
          color: this.colour,
        };
        this.nonce++;
        return item;
      }
      return v as VotingOption;
    });
    this.$emit("update:model", options);
  }
  search = null;

  filter(item: string | null, queryText: string, itemText: string): boolean {
    if (item?.length) return false;

    const hasValue = (val: string | null) => (val != null ? val : "");

    const text = hasValue(itemText);
    const query = hasValue(queryText);

    return (
      text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    );
  }
}
