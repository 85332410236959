import type Vue from "vue";
import {
  AgendaDTO,
  AgendaItemDTO,
  AgendaItemsDTO,
  AgendaListDTO,
} from "~/types/Agenda";
import { RoleListDTO } from "~/types/Role";
import { UserDTO, UserListDTO } from "~/types/User";
import { getInstance } from "../auth";
import { errorMap } from "./errors";

export interface ListQuery {
  fields?: string[];
  limit?: number;
  skip?: number;
}

function formatListQuery({ fields, limit, skip }: ListQuery): string {
  const queryString = [];
  if (Array.isArray(fields) && fields.length > 0) {
    queryString.push(...fields.map((f) => `fields[]=${f}`));
  }
  if (typeof limit === "number") {
    queryString.push(`limit=${limit}`);
  }
  if (typeof skip === "number") {
    queryString.push(`skip=${skip}`);
  }
  if (queryString.length === 0) {
    return "";
  }
  return `?${queryString.join("&")}`;
}

export async function sendVote(
  agendaId: number,
  itemId: number,
  vote: string
): Promise<AgendaItemDTO> {
  const path = `agendas/${agendaId}/items/${itemId}/vote`;
  const res = await callApi<AgendaItemDTO>({
    path,
    method: "POST",
    data: {
      value: vote,
    },
  });
  return res;
}

export async function getAgenda(
  agendaId?: number | string
): Promise<AgendaDTO> {
  const path = `agendas/${agendaId ?? "current"}`;
  const res = await callApi<AgendaDTO>({ path, method: "GET" });

  return res;
}

export async function getAgendas(opts: ListQuery = {}): Promise<AgendaListDTO> {
  const queryString = formatListQuery(opts);
  const path = `agendas${queryString}`;
  const res = await callApi<{ agendas: AgendaDTO[] }>({ path, method: "GET" });
  return res;
}

export async function createAgenda(data: AgendaDTO): Promise<AgendaDTO> {
  const path = "agendas";
  const res = await callApi<AgendaDTO>({ path, method: "POST", data });

  return res;
}

export async function updateAgenda(
  agendaId: number,
  data: Partial<AgendaDTO>
): Promise<AgendaDTO> {
  const path = `agendas/${agendaId}`;
  const res = await callApi<AgendaDTO>({ path, method: "PATCH", data });

  return res;
}

export async function deleteAgenda(agendaId: number): Promise<void> {
  const path = `agendas/${agendaId}`;
  const res = await callApi<void>({ path, method: "DELETE" });
  return res;
}

export async function getAgendaItems(
  agendaId: string | number
): Promise<AgendaItemsDTO> {
  const path = `agendas/${agendaId}/items`;
  const res = await callApi<AgendaItemsDTO>({ path, method: "GET" });
  return res;
}

export async function getAgendaItem(
  agendaId: number,
  itemId: number
): Promise<AgendaItemDTO> {
  const path = `agendas/${agendaId}/items/${itemId}`;
  const res = await callApi<AgendaItemDTO>({ path, method: "GET" });
  return res;
}

export async function updateAgendaItem(
  agendaId: number,
  data: AgendaItemDTO
): Promise<AgendaItemDTO> {
  const { myVote: _myVote, ...dto } = data;
  const path = `agendas/${agendaId}/items/${data.id === 0 ? "" : data.id}`;
  const res = await callApi<AgendaItemDTO>({
    path,
    method: "PATCH",
    data: dto,
  });

  return res;
}

export async function deleteAgendaItem(
  agendaId: number,
  itemId: number
): Promise<void> {
  const path = `agendas/${agendaId}/items/${itemId}`;
  const res = await callApi<void>({ path, method: "DELETE" });
  return res;
}

export async function createAgendaItem(
  agendaId: number,
  data: AgendaItemDTO
): Promise<AgendaItemDTO> {
  const path = `agendas/${agendaId}/items`;
  const res = await callApi<AgendaItemDTO>({ path, method: "POST", data });

  return res;
}

export async function getMe(): Promise<UserDTO> {
  const path = "me";
  const res = await callApi<UserDTO>({ path, method: "GET" });
  return res;
}

export async function updateMe(user: Partial<UserDTO>): Promise<UserDTO> {
  const path = "me";
  const res = await callApi<UserDTO>({ path, method: "POST", data: user });
  return res;
}

export async function getUsers(page = 1, count = 10): Promise<UserListDTO> {
  const path = createPath("admin/users", { page, count });
  const res = await callApi<UserListDTO>({ path, method: "GET" });
  return res;
}

export async function deleteUser(userId: string): Promise<void> {
  const path = `admin/users/${userId}`;
  await callApi({ path, method: "DELETE" });
  return;
}

export async function getRoles(): Promise<RoleListDTO> {
  const path = "admin/roles";
  const res = await callApi<RoleListDTO>({ path, method: "GET" });
  return res;
}

export async function setUserRoles(
  userId: string,
  roles: string[]
): Promise<unknown> {
  const path = `admin/users/${userId}/roles`;
  const res = await callApi({ path, method: "PUT", data: { roles } });
  return res;
}

function createPath(
  base: string,
  query: Record<string, string | number>
): string {
  if (query) {
    const q = Object.entries(query).reduce((agg: string, [k, v]) => {
      return agg.length > 0 ? `${agg}&${k}=${v}` : `?${k}=${v}`;
    }, "");
    return `${base}${q}`;
  }
  return base;
}

async function callApi<T = unknown>({
  path,
  method = "GET",
  data,
  headers,
}: {
  path: string;
  method?: string;
  data?: unknown;
  headers?: Record<string, string>;
}): Promise<T> {
  const token = await getToken();
  const baseUrl = "api";
  const url = `${baseUrl}/${path}`;
  const requestHeaders: HeadersInit = {
    "Content-Type": "application/json",
    ...headers,
  };
  if (token) {
    requestHeaders.authorization = `Bearer ${token}`;
  }
  try {
    const res = await fetch(url, {
      method,
      headers: requestHeaders,
      body: JSON.stringify(data),
    });

    if (res.ok) {
      if (res.status === 204) {
        return null as any;
      }
      return res.json();
    }
    console.error("Call failed", res.status, res.statusText);
    throw getError(res.status);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function getToken(): Promise<string | void> {
  try {
    const auth = getInstance() as Vue["$auth"];
    return await auth.getTokenSilently();
  } catch (e) {
    return "";
  }
}

export function getError(status: number): Error {
  if (errorMap[status]) {
    return errorMap[status];
  }
  return new Error("Error sending to API");
}
