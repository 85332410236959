import { RoleDTO } from "~/types/Role";

export class Role implements RoleDTO {
  id: string;
  name: string;
  description: string;

  constructor(role?: RoleDTO) {
    if (role) {
      this.id = role.id;
      this.name = role.name;
      this.description = role.description;
    } else {
      this.id = "";
      this.name = "Unknown";
      this.description = "";
    }
  }
}
