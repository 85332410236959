import Vue, { VueConstructor } from "vue";
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  IdToken,
  LogoutOptions,
  PopupConfigOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-spa-js";
import type { Store } from "vuex";

type RedirectCallback = (state: Record<string, unknown>) => void;

interface UseAuth0Options {
  onRedirectCallback: RedirectCallback;
  redirectUri: string;
  store: Store<unknown> | null;
}

interface Auth0ClientMethods {
  loginWithPopup(
    options?: PopupLoginOptions,
    config?: PopupConfigOptions
  ): Promise<void> | undefined;
  handleRedirectCallback(): Promise<void>;
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
  getIdTokenClaims(
    o?: GetIdTokenClaimsOptions
  ): Promise<IdToken | undefined> | void;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): Promise<void> | void;
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK: RedirectCallback = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance: Vue;

/** Returns the current instance of the SDK */
export const getInstance = (): Vue => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  store = null,
  ...options
}: Auth0ClientOptions & UseAuth0Options): Vue => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue<
    {
      loading: boolean;
      isAuthenticated: boolean;
      user: Record<string, unknown> | undefined;
      auth0Client: Auth0Client | null;
      popupOpen: boolean;
      error: Error | null;
      store: Store<unknown> | null;
    },
    Auth0ClientMethods
  >({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        store,
      };
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(
        options?: PopupLoginOptions,
        config?: PopupConfigOptions
      ) {
        this.popupOpen = true;

        try {
          await this.auth0Client?.loginWithPopup(options, config);
          this.user = await this.auth0Client?.getUser();
          this.store
            ?.dispatch("SET_USER", this.user)
            .then(() =>
              this.user ? this.store?.dispatch("GET_CURRENT_USER") : null
            );
          this.isAuthenticated =
            (await this.auth0Client?.isAuthenticated()) ?? false;
          this.error = null;
        } catch (e) {
          this.error = e as Error;
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client?.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client?.handleRedirectCallback();
          this.user = await this.auth0Client?.getUser();
          this.store
            ?.dispatch("SET_USER", this.user)
            .then(() => this.store?.dispatch("GET_CURRENT_USER"));
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e as Error;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      async loginWithRedirect(o?: RedirectLoginOptions) {
        return this.auth0Client?.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      async getIdTokenClaims(o?: GetIdTokenClaimsOptions) {
        return this.auth0Client?.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently(o?: GetTokenSilentlyOptions) {
        const token = await this.auth0Client?.getTokenSilently(o);
        this.store?.dispatch("SET_ACCESS_TOKEN", token);
        return token || "";
      },
      /** Gets the access token using a popup window */

      async getTokenWithPopup(o?: GetTokenWithPopupOptions) {
        return this.auth0Client?.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o?: LogoutOptions) {
        this.store?.dispatch("SET_USER", null);
        this.store?.dispatch("SET_ACCESS_TOKEN", null);
        return this.auth0Client?.logout(o);
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri,
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client?.handleRedirectCallback();

          this.error = null;

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e as Error;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client?.isAuthenticated();
        this.user = await this.auth0Client?.getUser();
        this.store
          ?.dispatch("SET_USER", this.user)
          .then(() =>
            this.user ? this.store?.dispatch("GET_CURRENT_USER") : null
          );
        this.isAuthenticated && (await this.getTokenSilently());
        this.loading = false;
      }
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(
    Vue: VueConstructor,
    options: Auth0ClientOptions & UseAuth0Options
  ): void {
    Vue.prototype.$auth = useAuth0(options);
  },
};
