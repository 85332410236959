import jwtDecode from "jwt-decode";
import { ActionTree } from "vuex";
import { User as Auth0User } from "@auth0/auth0-spa-js";
import { AgendaDTO, AgendaItemDTO } from "~/types/Agenda";

import { Agenda } from "../data/Agenda";
import { AgendaItem } from "../data/AgendaItem";
import { User } from "../data/User";
import { Role } from "../data/Role";

import { RootState } from ".";
import * as api from "./api";
import { UnauthorizedError } from "./errors/UnauthorizedError";
import { ForbiddenError } from "./errors/ForbiddenError";
import { RolesMap } from "~/types/Role";
import { UserDTO } from "~/types/User";

function getErrorName(error: unknown, defaultName = "ERROR_API"): string {
  if (error instanceof UnauthorizedError) return "ERROR_NOT_LOGGED_IN";
  if (error instanceof ForbiddenError) return "ERROR_NOT_PERMITTED";
  return defaultName;
}

const agendaActions: ActionTree<RootState, RootState> = {
  async GET_AGENDA_LIST({ commit }, { limit, skip }: api.ListQuery) {
    commit("APP_LOADING");
    try {
      const res = await api.getAgendas({
        fields: [
          "agenda.id",
          "agenda.date",
          "items.id",
          "items.title",
          "items.order",
          "items.voting",
        ],
        limit,
        skip,
      });
      const agendas = res.agendas.map((a) => new Agenda(a));
      return { agendas, page: res.metadata?.page, total: res.metadata?.total };
    } catch (err) {
      commit(getErrorName(err, "ERROR_AGENDA_LIST"), err);
      throw err;
    } finally {
      commit("APP_LOADING_COMPLETE");
    }
  },
  async GET_AGENDA({ commit }, agendaId: number | string) {
    commit("APP_LOADING");
    try {
      commit("SET_AGENDA", null);
      commit("SET_ERROR_AGENDA", null);
      const resp = await api.getAgenda(agendaId);
      const agenda: AgendaDTO = new Agenda(resp);
      commit("SET_AGENDA", agenda);
    } catch (err) {
      commit(getErrorName(err, "SET_ERROR_AGENDA"), err);
    } finally {
      commit("APP_LOADING_COMPLETE");
    }
  },
  async ADD_AGENDA({ commit }, newAgenda: AgendaDTO): Promise<Agenda> {
    try {
      const resp = await api.createAgenda(newAgenda);
      const agenda = new Agenda(resp);
      commit("SET_AGENDA", agenda);
      return agenda;
    } catch (err) {
      commit(getErrorName(err, "ERROR_ADD_AGENDA"), err);
      throw err;
    }
  },
  async UPDATE_AGENDA({ commit, state }, update: AgendaDTO): Promise<Agenda> {
    if (!state.agenda) throw Error("No agenda set"); // TODO message
    try {
      const resp = await api.updateAgenda(state.agenda.id, update);
      const agenda = new Agenda(resp);
      commit("SET_AGENDA", agenda);
      return agenda;
    } catch (err) {
      commit(getErrorName(err, "ERROR_UPDATE_AGENDA"), err);
      throw err;
    }
  },
  async SET_AGENDA_HOST(
    { commit, state },
    {
      agendaId = state.agenda?.id,
      userId,
    }: { agendaId?: number; userId: string }
  ): Promise<Agenda> {
    if (!agendaId) throw Error("No agenda set"); // TODO message
    try {
      const resp = await api.updateAgenda(agendaId, { hostUserId: userId });
      const agenda = new Agenda(resp);
      if (agendaId === state.agenda?.id) {
        commit("SET_AGENDA", agenda);
      }
      return agenda;
    } catch (err) {
      commit(getErrorName(err, "ERROR_UPDATE_AGENDA"), err);
      throw err;
    }
  },
  async DELETE_AGENDA({ commit, state }): Promise<void> {
    if (!state.agenda) throw Error("No agenda set");
    const agenda = state.agenda;
    try {
      const agendaId: number = agenda.id;
      await api.deleteAgenda(agendaId);
      commit("REMOVE_AGENDA");
    } catch (err) {
      commit(getErrorName(err, "ERROR_DELETE_AGENDA"), err);
      throw err;
    }
  },
};

const agendaItemActions: ActionTree<RootState, RootState> = {
  async SET_CURRENT_ITEM({ commit, state }, item: AgendaItem) {
    if (state.agenda?.items.find((i) => i.id === item.id)) {
      commit("ITEM_LOADING");
      try {
        const resp = await api.getAgendaItem(state.agenda.id, item.id);
        const agendaItem: AgendaItem = new AgendaItem(resp);
        commit("SET_CURRENT_AGENDA_ITEM", agendaItem);
      } catch (e) {
        commit(getErrorName(e, "ERROR_CURRENT_AGENDA_ITEM"), e);
      } finally {
        commit("ITEM_LOADING_COMPLETE");
      }
    } else {
      commit("ERROR_CURRENT_AGENDA_ITEM", new Error("Item not found"));
    }
  },
  async UPDATE_ITEM(
    { commit, state },
    item: AgendaItemDTO
  ): Promise<AgendaItem> {
    if (!state.agenda) throw Error("No agenda set");
    try {
      const agendaId: number = state.agenda?.id;
      const resp = await api.updateAgendaItem(agendaId, item);
      const updatedItem = new AgendaItem(resp);
      commit("UPDATE_ITEM", updatedItem);
      if (
        !state.currentAgendaItem ||
        state.currentAgendaItem.id === updatedItem.id
      ) {
        commit("SET_CURRENT_AGENDA_ITEM", updatedItem);
      }
      return updatedItem;
    } catch (err) {
      commit(getErrorName(err, "ERROR_UPDATE_ITEM"), err);
      throw err;
    }
  },
  async ADD_ITEM({ commit, state }, item: AgendaItemDTO): Promise<AgendaItem> {
    if (!state.agenda) throw Error("No agenda set");
    const agenda = state.agenda;
    try {
      const agendaId: number = agenda.id;
      const order = Math.max(
        ...agenda.items.map((i) => i.order || Number.NEGATIVE_INFINITY),
        agenda.items.length
      );
      item.order = order;
      const resp = await api.createAgendaItem(agendaId, item);
      const addedItem = new AgendaItem(resp);
      commit("ADD_ITEM", addedItem);
      return addedItem;
    } catch (err) {
      commit(getErrorName(err, "ERROR_ADD_ITEM"), err);
      throw err;
    }
  },
  async DELETE_ITEM({ commit, state }, itemId: number): Promise<void> {
    if (!state.agenda) throw Error("No agenda set");
    const agenda = state.agenda;
    try {
      const agendaId: number = agenda.id;
      await api.deleteAgendaItem(agendaId, itemId);
      commit("REMOVE_ITEM", itemId);
    } catch (err) {
      commit(getErrorName(err, "ERROR_DELETE_ITEM"), err);
      throw err;
    }
  },
};

const voteActions: ActionTree<RootState, RootState> = {
  async SEND_VOTE(
    { commit, state },
    { itemId, vote }: { itemId: number; vote: string }
  ): Promise<void> {
    const agendaId = state.agenda?.id ?? 0;
    commit("APP_LOADING");
    try {
      const resp = await api.sendVote(agendaId, itemId, vote);
      // commit("SET_VOTE_ON_ITEM", { agendaId, itemId, vote });
      const updatedItem = new AgendaItem(resp);
      commit("UPDATE_ITEM", updatedItem);
      if (
        !state.currentAgendaItem ||
        state.currentAgendaItem.id === updatedItem.id
      ) {
        commit("SET_CURRENT_AGENDA_ITEM", updatedItem);
      }
    } catch (err) {
      commit(getErrorName(err), err);
    } finally {
      commit("APP_LOADING_COMPLETE");
    }
  },
  async VOTE_COMPLETE({ commit, state }, isComplete) {
    const agendaId = state.agenda?.id ?? 0;
    const item = state.currentAgendaItem;
    if (item && (item.voting || item.votingDetails?.allowed)) {
      commit("APP_LOADING");
      const agendaItem = { ...item };
      agendaItem.votingDetails = {
        ...agendaItem.votingDetails,
        allowed: true,
        closed: isComplete,
      };
      try {
        const resp = await api.updateAgendaItem(agendaId, agendaItem);
        const updatedItem = new AgendaItem(resp);
        commit("UPDATE_ITEM", updatedItem);
        if (
          !state.currentAgendaItem ||
          state.currentAgendaItem.id === updatedItem.id
        ) {
          commit("SET_CURRENT_AGENDA_ITEM", updatedItem);
        }
      } catch (err) {
        commit(getErrorName(err), err);
      } finally {
        commit("APP_LOADING_COMPLETE");
      }
    }
  },
};

const adminActions: ActionTree<RootState, RootState> = {
  async GET_USER_LIST({ commit }, { limit = 10, skip = 0 }: api.ListQuery) {
    commit("USER_LIST_LOADING");
    try {
      const res = await api.getUsers(skip * limit, limit);
      const users = res.users.map((u) => new User(u));

      return {
        users,
      };
    } catch (err) {
      commit(getErrorName(err, "ERROR_USER_LIST"), err);
      throw err;
    } finally {
      commit("USER_LIST_LOADING_COMPLETE");
    }
  },
  async GET_ROLE_LIST({ commit }) {
    commit("ROLE_LIST_LOADING");
    try {
      const res = await api.getRoles();
      const roles = res.roles.reduce<RolesMap>((acc, u) => {
        const role = new Role(u);
        acc[role.id] = role;
        return acc;
      }, {});

      return roles;
    } catch (err) {
      commit(getErrorName(err, "ERROR_ROLE_LIST"), err);
      throw err;
    } finally {
      commit("ROLE_LIST_LOADING_COMPLETE");
    }
  },
  async SET_USER_ROLES(
    { commit },
    { userId, roles }: { userId: string; roles: string[] }
  ) {
    commit("SET_USER_ROLE_START");
    try {
      const res = await api.setUserRoles(userId, roles);
      return res;
    } catch (err) {
      commit(getErrorName(err, "ERROR_SET_USER_ROLES"), err);
    } finally {
      commit("SET_USER_ROLE_DONE");
    }
  },
  async DELETE_USER({ commit }, userId: string) {
    try {
      commit("USER_DELETING");
      await api.deleteUser(userId);
      commit("REMOVE_USER", userId);
    } catch (err) {
      console.log("ERROR USER DELETE");
      commit(getErrorName(err, "ERROR_USER_DELETE"), err);
      throw err;
    } finally {
      commit("USER_DELETED");
    }
  },
};

const userActions: ActionTree<RootState, RootState> = {
  async SET_USER({ commit }, user: Auth0User) {
    commit("SET_USER", user);
  },
  async GET_CURRENT_USER({ commit, state }): Promise<UserDTO> {
    try {
      commit("CURRENT_USER_LOADING");
      const res = await api.getMe();
      const user = { preferences: {}, ...state.user, ...res };
      commit("UPDATE_USER", user);
      return user;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      commit("CURRENT_USER_LOADING_COMPLETE");
    }
  },
  async UPDATE_CURRENT_USER(
    { commit },
    { preferences }: Partial<UserDTO>
  ): Promise<UserDTO> {
    try {
      commit("CURRENT_USER_UPDATING");
      const res = await api.updateMe({ preferences });
      commit("UPDATE_USER", res);
      return res;
    } catch (e) {
      console.error(e);
      commit("CURRENT_USER_UPDATING_ERROR");
      throw e;
    } finally {
      commit("CURRENT_USER_UPDATING_COMPLETE");
    }
  },
};

export const actions: ActionTree<RootState, RootState> = {
  ...agendaActions,
  ...agendaItemActions,
  ...voteActions,
  ...adminActions,
  ...userActions,
  SET_APP_LOADING({ state, commit }) {
    if (!state.appLoading) {
      commit("APP_LOADING");
    }
  },
  SET_APP_LOADING_COMPLETE({ state, commit }) {
    if (state.appLoading) {
      commit("APP_LOADING_COMPLETE");
    }
  },
  SET_ACCESS_TOKEN({ commit }, token: string) {
    let accessToken = null;
    if (token) {
      try {
        accessToken = jwtDecode(token);
      } catch (e) {
        console.error((e as Error).message);
      }
    }
    commit("SET_ACCESS_TOKEN", accessToken);
  },
};
