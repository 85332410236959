import Vue from "vue";
import Vuex, { Store } from "vuex";

import type { AccessToken } from "../types/Agenda";
import type { Agenda } from "~/data/Agenda";
import type { AgendaItem } from "~/data/AgendaItem";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { User } from "@auth0/auth0-spa-js";

Vue.use(Vuex);

export interface RootState {
  agenda: Agenda | null;
  agendaError: string | null;
  currentAgendaItem: AgendaItem | null;
  user: User | null;
  accessToken: AccessToken | null;
  errorList: Set<Error>;
  appLoading: boolean;
  itemLoading: boolean;
  userListLoading: boolean;
  roleListLoading: boolean;

  userLoading: boolean;
  userDeleting: boolean;
}

export type RootStore = Store<RootState>;

export default new Vuex.Store<RootState>({
  state: {
    agenda: null,
    agendaError: null,
    currentAgendaItem: null,
    user: null,
    accessToken: null,
    errorList: new Set(),
    appLoading: false,
    itemLoading: false,
    userListLoading: false,
    roleListLoading: false,

    userLoading: false,
    userDeleting: false,
  },
  actions,
  mutations,
  getters,
  modules: {},
});
