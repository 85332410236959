import {
  AgendaItemDTO,
  VoteDTO,
  VotingDetails,
  VotingResults,
} from "~/types/Agenda";
import { sanitize } from "dompurify";

export class AgendaItem implements AgendaItemDTO {
  constructor(agendaItem?: AgendaItemDTO) {
    if (agendaItem) {
      this.id = agendaItem.id;
      this.title = agendaItem.title;
      this.description = sanitize(agendaItem.description);
      this.voting = !!agendaItem.voting;
      this.results = agendaItem.results;
      this.votingDetails = agendaItem?.votingDetails;
      this.myVote = agendaItem.myVote;
      this.order = agendaItem.order;
    } else {
      this.id = 0;
      this.title = "";
      this.description = "";
      this.voting = false;
      this.order = 0;
    }
  }
  id: number;
  title: string;
  description: string;
  voting?: boolean;
  results?: VotingResults;
  votingDetails?: VotingDetails;
  myVote?: VoteDTO;
  order?: number;

  get isVotingComplete(): boolean {
    return !!this.results?.complete;
  }

  get yesVotes(): number {
    return this.results?.yes ?? 0;
  }

  get noVotes(): number {
    return this.results?.no ?? 0;
  }

  get abstainVotes(): number {
    return this.results?.abstain ?? 0;
  }

  get votingResult(): string {
    if (!this.isVotingComplete) {
      return "";
    }
    if (this.yesVotes > this.noVotes) {
      return "yes";
    } else if (this.noVotes > this.yesVotes) {
      return "no";
    }

    return "tie";
  }
}
