import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VList,{attrs:{"color":"transparent"}},[_c(VListItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.sortedItems),function(n){return _c(VListItem,{key:n.id,attrs:{"link":""},on:{"change":function($event){return _vm.selectItem(n)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(n.title)+" ")])],1),_c(VListItemIcon,[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(n.voting)?_c(VIcon,_vm._g({attrs:{"color":_vm.getVoteColour(n.votingResult)}},on),[_vm._v("mdi-vote")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Votable Item")])])],1)],1)}),1),_c(VDivider,{staticClass:"my-2"}),(_vm.hasItemCreatePermission)?_c('agenda-item-edit',{scopedSlots:_vm._u([{key:"default",fn:function({ on }){return [_c(VListItem,_vm._g({attrs:{"link":""}},on),[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Add Agenda Item ")])],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-plus-circle")])],1)],1)]}}],null,false,3458207122),model:{value:(_vm.addItemOpen),callback:function ($$v) {_vm.addItemOpen=$$v},expression:"addItemOpen"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }