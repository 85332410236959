
import { Component, Vue, Watch } from "vue-property-decorator";
import marked from "marked";

import type { AgendaItem } from "~/data/AgendaItem";
import type { VotingOption } from "~/types/Agenda";

import VoteButtons from "./VoteButtons.vue";
import AgendaMenu from "./AgendaMenu.vue";
import { Action, Getter } from "vuex-class";
import { sanitize } from "dompurify";

@Component({
  components: {
    AgendaMenu,
    VoteButtons,
  },
})
export default class AgendaDetails extends Vue {
  @Getter
  currentAgendaItem!: AgendaItem;

  vote: string | null = null;

  voteChanged(vote: string): Promise<void> {
    return this.sendVote({ itemId: this.currentAgendaItem.id, vote });
  }

  @Action("SEND_VOTE")
  sendVote!: (date: { itemId: number; vote: string }) => Promise<void>;

  @Action("VOTE_COMPLETE")
  votingComplete!: (isComplete: boolean) => Promise<void>;

  @Watch("currentAgendaItem")
  updatedItem(newItem: AgendaItem): void {
    console.log("ITEM_CHANGED", newItem, this.currentAgendaItem.myVote);
    this.vote = newItem.myVote?.value || null;
  }

  @Getter
  isItemLoading!: boolean;

  @Getter
  hasItemVotePermission!: boolean;
  @Getter
  hasItemUpdatePermission!: boolean;
  @Getter
  hasItemCreatePermission!: boolean;
  @Getter
  hasAgendaUpdatePermission!: boolean;
  @Getter
  hasAgendaCreatePermission!: boolean;

  get votingDisabled(): boolean {
    return (
      this.currentAgendaItem.isVotingComplete ||
      !(this.$auth.isAuthenticated && this.hasItemVotePermission)
    );
  }

  get votingAllowed(): boolean {
    return !!this.currentAgendaItem.votingDetails?.allowed;
  }

  get markdownDescription(): string {
    return sanitize(marked(this.currentAgendaItem.description));
  }

  get voteOptions(): VotingOption[] {
    return (
      this.currentAgendaItem.votingDetails?.options?.map((o) => {
        if (typeof o === "string") {
          return {
            title: o,
            value: o.toLowerCase(),
          };
        }
        return o;
      }) || []
    );
    // return [
    //   {
    //     title: "Yes",
    //     value: "yes",
    //     icon: "mdi-check-circle-outline",
    //     color: "success",
    //   },
    //   {
    //     title: "No",
    //     value: "no",
    //     icon: "mdi-close-circle-outline",
    //     color: "error",
    //   },
    //   {
    //     title: "Abstain",
    //     value: "abstain",
    //     icon: "mdi-minus-circle-outline",
    //   },
    // ];
  }
}
