
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";

@Component({
  components: {
    EditorContent,
  },
})
export default class RitchEditor extends Vue {
  editor: Editor | null = null;

  toggleFormat = [];

  get markToggle(): string[] {
    const formats: string[] = [];
    if (this.editor?.isActive("bold")) formats.push("bold");
    if (this.editor?.isActive("italic")) formats.push("italic");
    if (this.editor?.isActive("strikethrough")) formats.push("strikethrough");
    return formats;
  }
  set markToggle(_values: string[]) {
    return;
  }

  get headerToggle(): string | null {
    if (this.editor?.isActive("heading", { level: 1 })) return "h1";
    if (this.editor?.isActive("heading", { level: 2 })) return "h2";
    if (this.editor?.isActive("heading", { level: 3 })) return "h3";
    if (this.editor?.isActive("heading", { level: 4 })) return "h4";

    return null;
  }
  set headerToggle(_values: string | null) {
    return;
  }

  get justifyToggle(): string {
    if (this.editor?.isActive({ textAlign: "left" })) return "left";
    if (this.editor?.isActive({ textAlign: "center" })) return "center";
    if (this.editor?.isActive({ textAlign: "right" })) return "right";
    if (this.editor?.isActive({ textAlign: "justify" })) return "justify";

    return "left";
  }
  set justifyToggle(_values: string) {
    return;
  }

  @Prop()
  value!: string | null;

  @Watch("value")
  valueChanged(newValue: string | null): void {
    if (newValue === this.editor?.getHTML()) {
      return;
    }
    this.editor?.commands.setContent(newValue, false);
  }

  mounted(): void {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
      ],
      onUpdate: () => {
        this.$emit("input", this.editor?.getHTML());
      },
    });
  }
  beforeDestroy(): void {
    this.editor?.destroy();
  }
}
