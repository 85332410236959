
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";

import type { AgendaDTO, AgendaItemDTO } from "~/types/Agenda";

import AgendaItemList from "../components/AgendaItemList.vue";
import AgendaDetails from "../components/AgendaDetails.vue";

@Component({
  components: {
    AgendaItemList,
    AgendaDetails,
  },
})
export default class Home extends Vue {
  @State
  agenda!: AgendaDTO;

  @State
  agendaError!: string | null;

  get agendaTitle(): string {
    const date = new Date(this.agenda?.date);
    if (isNaN(date.getTime())) {
      return "";
    }
    date.setMinutes(date.getTimezoneOffset());
    return `${date.toLocaleDateString()}`;
  }

  @Getter
  isAppLoading!: boolean;

  @Action("GET_AGENDA")
  getAgendaItems!: (agendaId?: number | string) => Promise<AgendaItemDTO[]>;

  async created(): Promise<void> {
    await this.getAgendaItems(this.$route.params.agendaDate);
  }
}
