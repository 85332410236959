
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TagsInput from "./TagInput.vue";

import TextButton from "./TextButton.vue";

import { AgendaItem } from "../data/AgendaItem";
import type { AgendaItemDTO, VotingOption } from "~/types/Agenda";

import Editor from "./Editor.vue";
import VoteButtons from "./VoteButtons.vue";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    Editor,
    VoteButtons,
    TagsInput,
    TextButton,
  },
})
export default class AgendaItemEditDialog extends Vue {
  @Prop()
  item?: AgendaItem;

  @Prop({ default: false })
  value!: boolean;

  get dialog(): boolean {
    return this.value;
  }
  set dialog(newValue: boolean) {
    this.$emit("input", newValue);
  }

  @Watch("item")
  itemChanged(update?: AgendaItem): void {
    this.title = update?.title ?? "";
    this.description = update?.description ?? "";
    this.voting = !!update?.votingDetails?.allowed;

    console.log(
      "NEW VOTE OPTION",
      update?.votingDetails?.options,
      this.defaultOptions
    );
    this.voteOptions = update?.votingDetails?.options?.map(
      this.toVotingOption
    ) || [...this.defaultOptions];
  }

  loading = false;

  title: string = this.item?.title || "";

  description: string = this.item?.description ?? "";

  voting?: boolean = this.item?.votingDetails?.allowed ?? false;

  defaultOptions: VotingOption[] = [
    {
      title: "Yes",
      value: "yes",
      color: "green",
    },
    {
      title: "No",
      value: "no",
      color: "red",
    },
    {
      title: "Abstain",
      value: "abstain",
    },
  ];

  toVotingOption(val: string | VotingOption): VotingOption {
    if (typeof val === "object") return val;
    return {
      title: `${val[0].toUpperCase()}${val.substring(1)}`,
      value: val,
    };
  }

  voteOptions: VotingOption[] = this.item?.votingDetails?.options?.map(
    this.toVotingOption
  ) || [...this.defaultOptions];

  clear(): void {
    this.title = "";
    this.description = "";
    this.voting = false;
    this.voteOptions = [...this.defaultOptions];
  }

  @Getter
  hasItemVotePermission!: boolean;
  @Getter
  hasItemUpdatePermission!: boolean;

  @Action("ADD_ITEM")
  saveNew!: (item: AgendaItemDTO) => Promise<void>;
  @Action("UPDATE_ITEM")
  saveUpdate!: (item: AgendaItemDTO) => Promise<void>;

  async save(): Promise<void> {
    this.loading = true;
    const item = new AgendaItem({
      id: this.item?.id || 0,
      title: this.title,
      description: this.description,
      votingDetails: this.voting
        ? {
            allowed: true,
            options: this.voteOptions,
          }
        : undefined,
    });
    try {
      if (item.id === 0) {
        await this.saveNew(item);
      } else {
        await this.saveUpdate(item);
      }
      this.close();
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  close(): void {
    this.loading = false;
    this.dialog = false;
    this.$emit("close");
    this.clear();
  }
}
