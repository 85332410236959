
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import type { Agenda } from "~/data/Agenda";
import type { AgendaItem } from "~/data/AgendaItem";
import type { User } from "~/data/User";
import AgendaEdit from "./AgendaEdit.vue";
import AgendaItemEdit from "./AgendaItemEdit.vue";

@Component({
  components: {
    AgendaEdit,
    AgendaItemEdit,
  },
})
export default class AgendaMenu extends Vue {
  fab = false;
  showAgendaDialog = false;
  showItemDialog = false;

  agenda: Agenda | null = null;
  item: AgendaItem | null = null;

  @Action("DELETE_ITEM")
  deleteItem!: (itemId: number) => Promise<void>;

  @Action("DELETE_AGENDA")
  deleteAgenda!: (agendaId: number) => Promise<void>;

  @Action("SET_AGNDA_HOST")
  setAgendaHost!: (args: { agendaId: number; userId: string }) => Promise<void>;

  async removeAgendaItem(): Promise<void> {
    if (this.currentAgendaItem) {
      this.deleteItem(this.currentAgendaItem.id);
    }
  }

  async removeAgenda(): Promise<void> {
    if (this.agenda) {
      this.deleteAgenda(this.agenda.id);
    }
  }

  async setHost(): Promise<void> {
    if (this.currentAgenda) {
      this.setAgendaHost({
        agendaId: this.currentAgenda.id,
        userId: this.currentUser.id,
      });
    }
  }

  showAgendaEdit(edit?: boolean): void {
    if (edit) {
      this.agenda = this.currentAgenda;
    }
    this.showAgendaDialog = true;
  }

  closedAgendaEdit(): void {
    this.agenda = null;
  }

  showItemEdit(edit?: boolean): void {
    if (edit) {
      this.item = this.currentAgendaItem;
    }
    this.showItemDialog = true;
  }

  closedAgendaItemEdit(): void {
    this.item = null;
  }

  @Getter
  currentAgenda!: Agenda;
  @Getter
  currentAgendaItem!: AgendaItem;
  @Getter
  currentUser!: User;
  @Getter
  hasItemUpdatePermission!: boolean;
  @Getter
  hasItemCreatePermission!: boolean;
  @Getter
  hasAgendaUpdatePermission!: boolean;
  @Getter
  hasAgendaCreatePermission!: boolean;

  get showMenu(): boolean {
    return (
      this.hasItemUpdatePermission ||
      this.hasItemCreatePermission ||
      this.hasAgendaUpdatePermission ||
      this.hasAgendaCreatePermission
    );
  }
}
