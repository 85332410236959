import Vue from "vue";
import { MutationTree } from "vuex";
import { AccessToken } from "~/types/Agenda";
import { Agenda } from "~/data/Agenda";
import { AgendaItem } from "~/data/AgendaItem";
import { RootState } from ".";
import { UserDTO } from "~/types/User";
import { User } from "@auth0/auth0-spa-js";

const appMutations: MutationTree<RootState> = {
  ERROR_NOT_PERMITTED(state, error) {
    console.log(error);
    if (!state.errorList.has(error)) {
      state.errorList.add(error);
    }
  },
  ERROR_NOT_LOGGGED_IN(state, error) {
    console.log(error);
    if (!state.errorList.has(error)) {
      state.errorList.add(error);
    }
  },
  ERROR_API(state, error) {
    console.log(error);
    if (!state.errorList.has(error)) {
      state.errorList.add(error);
    }
  },
  APP_LOADING_COMPLETE(state) {
    state.appLoading = false;
  },
  APP_LOADING(state) {
    state.appLoading = true;
  },
  ITEM_LOADING_COMPLETE(state) {
    state.itemLoading = false;
  },
  ITEM_LOADING(state) {
    state.itemLoading = true;
  },
};

const agendaMutations: MutationTree<RootState> = {
  SET_AGENDA(state, agenda: Agenda) {
    console.log("SET_AGENDA", agenda);
    state.agenda = agenda;
    // if current one is not set or not in agenda
    state.currentAgendaItem = agenda?.items[0] || null;
  },
  SET_ERROR_AGENDA(state, err: Error | null) {
    state.agendaError = err ? err.message : null;
  },
  SET_CURRENT_AGENDA_ITEM(state, agendaItem: AgendaItem) {
    console.log("SET_CURRENT_AGENDA_ITEM", agendaItem);
    state.currentAgendaItem = agendaItem;
  },
  ERROR_CURRENT_AGENDA_ITEM(_state, err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return undefined;
  },
  ADD_AGENDA_ITEM(state, agendaItem: AgendaItem) {
    console.log("ADD_AGENDA_ITEM", agendaItem);
    state.agenda?.items.push(agendaItem);
  },
  ERROR_ADD_AGENDA_ITEM(_state, err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return undefined;
  },
  UPDATE_ITEM(state, item: AgendaItem): void {
    console.log("UPDATE_ITEM", item);
    if (!state.agenda) return;
    const itemIndex = state.agenda.items.findIndex((it) => it.id === item.id);
    if (itemIndex < 0) {
      console.warn("Item not in current agenda");
      return;
    }
    Vue.set(state.agenda.items, itemIndex, item);
  },
  ADD_ITEM(state, item: AgendaItem): void {
    console.log("ADD_ITEM", item);
    if (!state.agenda) return;
    const itemIndex = state.agenda.items.findIndex((it) => it.id === item.id);
    if (itemIndex >= 0) {
      console.warn("Item already in current agenda");
      return;
    }
    state.agenda.items.push(item);
  },
  REMOVE_ITEM(state, itemId: number): void {
    console.log("REMOVE_ITEM");
    if (!state.agenda) return;
    const itemIndex = state.agenda.items.findIndex((it) => it.id === itemId);
    if (itemIndex < 0) {
      console.warn("Item not in current agenda");
      return;
    }
    state.agenda.items.splice(itemIndex, 1);
    if (state.agenda.items.length >= itemIndex) {
      state.currentAgendaItem = state.agenda.items[itemIndex];
    } else {
      state.currentAgendaItem =
        state.agenda.items[state.agenda.items.length - 1];
    }
  },
};

const adminMutations: MutationTree<RootState> = {
  USER_LIST_LOADING(state) {
    state.userListLoading = true;
  },
  USER_LIST_LOADING_COMPLETE(state) {
    state.userListLoading = false;
  },
  USER_DELETING(state) {
    state.userDeleting = true;
  },
  USER_DELETED(state) {
    state.userDeleting = false;
  },
  ROLE_LIST_LOADING(state) {
    state.roleListLoading = true;
  },
  ROLE_LIST_LOADING_COMPLETE(state) {
    state.roleListLoading = false;
  },
  CURRENT_USER_LOADING(state) {
    state.userLoading = true;
  },
  CURRENT_USER_LOADING_COMPLETE(state) {
    state.userLoading = false;
  },
  CURRENT_USER_LOADING_ERROR(state, error) {
    console.error("Current user loading error", error);
    state.userLoading = false;
  },
  CURRENT_USER_UPDATING(state) {
    state.userLoading = true;
  },
  CURRENT_USER_UPDATING_COMPLETE(state) {
    state.userLoading = false;
  },
  CURRENT_USER_UPDATING_ERROR(state, error) {
    console.error("Current user update error", error);
    state.userLoading = false;
  },
};

export const mutations: MutationTree<RootState> = {
  ...appMutations,
  ...agendaMutations,
  ...adminMutations,
  SET_USER(state, user: User) {
    state.user = user;
  },
  UPDATE_USER(state, user: UserDTO) {
    if (!state.user) {
      return;
    }
    if (user.email) {
      state.user.email = user.email;
    }
    if (user.name) {
      state.user.name = user.name;
    }
    if (user.preferences) {
      state.user.preferences = user.preferences;
    }
  },
  SET_ACCESS_TOKEN(state, token: AccessToken) {
    state.accessToken = token;
  },
};
